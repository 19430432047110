import React from 'react';

import Image from '@atlaskit/image';

type ImageFromUrlProps = {
	src?: string;
	alt?: string;
};

export const ImageFromUrl = ({ src, alt }: ImageFromUrlProps) => {
	return <Image src={src} alt={alt} />;
};
