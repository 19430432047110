import { useCallback, useRef } from 'react';

import {
	BFF_REQUEST_STATUS_KEY,
	MAX_ATTEMPTS_DEFAULT,
	RequestStatus,
	RETRY_INTERVAL_DEFAULT,
} from '../../constants';
import { fetchJSON } from '../utils/request';

import { useCache } from './useCache';
import { useProductLinks } from './useProductLinks';
import { useStatus } from './useStatus';
import { useURLBuilder } from './useURLBuilder';

type useBFFProps = {
	request?: {
		shouldRetryOnException?: boolean;
		attemptInterval?: number;
		maxAttempts?: number;
	};
	cloudId?: string;
	orgId?: string;
};

async function wait(ms: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}

/**
 * Hook to use the Backend for Frontend.
 *
 * Ensure you check the isStaging() function in the request.ts file when
 * integrating this provider in a new product as it may need to be updated.
 *
 * We don't hook our Best Friends Forever. That's cruel.
 */
export function useBFFDataProvider({ request, cloudId, orgId }: useBFFProps) {
	const { items: cachedItems, addItemsToCache, clearCache, cacheKey, updateCacheKey } = useCache();
	const { setStatus, status } = useStatus();
	const { productLinks } = useProductLinks({ cloudId, orgId });
	const { buildURL } = useURLBuilder();

	const currentRequest = useRef<Promise<any> | null>(null);

	const fetchData = useCallback(
		async <T>(url: string, data?: any, attemptCount = 1): Promise<any> => {
			if (url === '') {
				throw new Error('URL is empty');
			}

			setStatus(RequestStatus.LOADING, BFF_REQUEST_STATUS_KEY);

			try {
				const response = await fetchJSON<T>(url, {});
				switch (response.status) {
					case RequestStatus.ERROR:
						throw response.error;
					case RequestStatus.COMPLETE:
						setStatus(RequestStatus.COMPLETE, BFF_REQUEST_STATUS_KEY);
						currentRequest.current = null;
						return response.data;
				}
			} catch (e) {
				const maxAttempts = request?.maxAttempts ?? MAX_ATTEMPTS_DEFAULT;

				if (request?.shouldRetryOnException && attemptCount < maxAttempts) {
					await wait(request?.attemptInterval ?? RETRY_INTERVAL_DEFAULT);
					return await fetchData(url, data, attemptCount + 1);
				} else {
					currentRequest.current = null;
					setStatus(RequestStatus.ERROR);
					throw new Error('Max attempts reached');
				}
			}
		},
		[request?.attemptInterval, request?.maxAttempts, request?.shouldRetryOnException, setStatus],
	);

	/**
	 * Helper function to build a URL and fetch data.
	 */
	const buildURLAndFetchData = useCallback(
		async <T>(requestedAPSKeys: string[]): Promise<void> => {
			// Wait for the current request to end before firing another
			if (currentRequest.current) {
				return currentRequest.current;
			}

			const builtUrl = buildURL({
				requestedAPSKeys,
			});

			if (builtUrl !== '') {
				currentRequest.current = fetchData<T>(builtUrl);
				const fetchedData = await currentRequest.current;

				if (fetchedData.sites && fetchedData.sites.length) {
					addItemsToCache(fetchedData.sites);
				}
			}
		},
		[addItemsToCache, buildURL, fetchData],
	);

	return {
		status,
		cachedItems,
		cacheKey,
		updateCacheKey,
		addItemsToCache,
		clearCache,
		buildURL,
		fetchData,
		buildURLAndFetchData,
		productLinks,
	};
}
