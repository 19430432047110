import { useCallback, useContext, useEffect, useMemo } from 'react';

import { RequestStatus } from '../../constants';
import { BFFContext } from '../context';

export function useStatus() {
	const {
		setValue,
		value: { status },
		currentLoadingRequests,
		updateCurrentLoadingRequests,
	} = useContext(BFFContext);

	const isComplete = useMemo(() => status === RequestStatus.COMPLETE, [status]);
	const isError = useMemo(() => status === RequestStatus.ERROR, [status]);
	const isLoading = useMemo(() => status === RequestStatus.LOADING, [status]);
	const isLoaded = useMemo(() => status !== RequestStatus.LOADING, [status]);

	// Globally update the status based on the request queue for the BFF
	useEffect(() => {
		if (!currentLoadingRequests.length && status === RequestStatus.LOADING) {
			// Ensure that the error state remains if it was previously set
			setValue((prev) => {
				if (prev.status === RequestStatus.ERROR) {
					return prev;
				}
				return { ...prev, status: RequestStatus.COMPLETE };
			});
		}
	}, [currentLoadingRequests, setValue, status]);

	// Globally manage the request queue of any calls to the BFF
	const setStatus = useCallback(
		(newStatus: RequestStatus, integratingComponent?: string) => {
			if (newStatus === RequestStatus.LOADING && integratingComponent) {
				updateCurrentLoadingRequests(integratingComponent, RequestStatus.LOADING);
			}

			if (newStatus === RequestStatus.COMPLETE) {
				if (integratingComponent) {
					updateCurrentLoadingRequests(integratingComponent, RequestStatus.COMPLETE);
				}
			}

			if (newStatus === RequestStatus.ERROR) {
				if (integratingComponent) {
					updateCurrentLoadingRequests(integratingComponent, RequestStatus.ERROR);
				}
			}

			// Don't allow the status to be marked as anything other than ERROR
			// if an error is pre-existing
			setValue((prev) => {
				if (prev.status === RequestStatus.ERROR) {
					return prev;
				}

				return {
					...prev,
					status: newStatus === RequestStatus.ERROR ? RequestStatus.ERROR : RequestStatus.LOADING,
				};
			});
		},
		[updateCurrentLoadingRequests, setValue],
	);

	return {
		status,
		isComplete,
		isError,
		isLoading,
		isLoaded,
		setStatus,
	};
}
